import { useEffect, useState } from "react";
import { CalendarApi } from "../api";
import { Badge, Box, Card, Chip, Grid, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
// Set moment timezone to local timezone

function CalendarScheduling() {
    const [availableMoments, setAvailableMoments] = useState< Moment[][] | null>(null);
  
    useEffect(() => {
      // Get free time from now up to 4 weeks
      const calenderApi = new CalendarApi();
      calenderApi.getFreeTimeCalendarFreeTimeGet({
        startTime: new Date(Date.now()),
        endTime: new Date(Date.now() + 4 * 7 * 24 * 60 * 60 * 1000),
      }).then((response) => {
            const momentArray = response.map((array) => [moment(array[0]), moment(array[1])])
            console.log(momentArray);
          setAvailableMoments(momentArray);
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

    const[selectedDate, setSelectedDate] = useState<Moment | null>(null);
    
    const pickerDayProps = (props: PickersDayProps<Moment>) => {
        const { day,outsideCurrentMonth ,...other} = props; 
        const dayLater = day.clone().add(1, "day");
        const isAvailable = availableMoments?.some((moment) => (moment[1].isAfter(day) && moment[0].isBefore(dayLater)));
        // console.log("Day", day.toString(), "Day after", dayLater.toString(), 
        // "is available", isAvailable);
        return     <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isAvailable && !outsideCurrentMonth ? '🟢' : undefined}
      >
        <PickersDay {...other}outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    }
    
    const getMomentsForDay = (day: Moment) => {
        if (!availableMoments) {
            return [];
        }
        const dayLater = day.clone().add(1, "day");
        return availableMoments.filter((moment) => (moment[1].isAfter(day) && moment[0].isBefore(dayLater)))
    }
    console.log("Selected date", selectedDate);
    return (
        <Card sx={{ padding: 4, minHeight: "100%" }}>
                <Typography variant="h4">Schedule a meeting</Typography>
                

                <Grid container>
                    <Grid item md={4} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment} >
                            <DateCalendar
                                value={selectedDate}
                                onChange={(newDate) => setSelectedDate(newDate)}
                                minDate={moment().startOf('week')}
                                maxDate={moment().add(4, "weeks").startOf('week')}
                                views={["day"]}
                                slots={{ day: pickerDayProps }}
    
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Box>
                            <Typography variant="h5">Available moments for: {selectedDate?.format("YYYY-MM-DD")}</Typography>
                            <Grid container spacing={1}>
                                {selectedDate && getMomentsForDay(selectedDate).map((moment) => {
                                    let start = moment[0]
                                    let end = moment[1]

                                    if (start.isBefore(selectedDate)) {
                                        start = selectedDate.clone();
                                    }
                                    if (end.isAfter(selectedDate.clone().add(1, "day").subtract(1, "minute"))) {
                                        end = selectedDate.clone().add(1, "day").subtract(1, "minute");
                                    }

                                    return <Grid item>
                                        <Chip key={moment.toString()} label={`${start.format("HH:mm")} - ${end.format("HH:mm")}`}/>
                                    </Grid>
                                })} 
                                {selectedDate && getMomentsForDay(selectedDate).length === 0 && (
                                    <Typography variant="body1" p={1}>No moments available, please select another date.
                                    </Typography>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
        </Card>
    );
  }

  export default CalendarScheduling;
