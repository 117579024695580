// Page that is always loaded and determines which page to show based on the URL

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './home';

function AppRouter() {
  return (
    <Router>
    <Routes>
      <Route path="/" Component={Home} />
    </Routes>
    </Router>
  );
}

export default AppRouter;
