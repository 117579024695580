import { Box, Card, Grid, Typography, useTheme } from '@mui/material';
import CalendarScheduling from '../components/CalendarScheduling';


function Home() {
    const theme = useTheme();
    return (
        <Box style={{ width: '100%', minHeight: '100vh', backgroundColor: theme.palette.background.default }}>
            <Grid container>
                <Grid item xs={12} sx={{backgroundColor: theme.palette.primary.main}}>
                    <Box padding={4}>
                    <Typography variant="h3" color="primary.light">Stijn Lievaart</Typography>
                    </Box>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Card sx={{padding:4, minHeight:'100%'}}>
                    <Typography variant="h4" color="text.primary">Thank you for visiting my personal website.</Typography>

                    <Typography variant="body1" color="text.primary">
                        This website is still under construction, but in the meantime, book meetings with the component to the right. 
                    </Typography>
                    </Card>
                </Grid>
                
                <Grid item xs={12} md={8}>
                    <CalendarScheduling/>
                </Grid>

            </Grid>
                
        </Box>
    );
}

export default Home;;
