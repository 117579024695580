import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
        main: "#1976d2",
        light: "#fdfdfd",
        dark:"#1976d2"
        },
        secondary: {
        main: "#dc004e",
        },
        background: {
        default: "#f2f2f2",
        paper: "#ffffff",
        },
        text: {
        primary: "#000000",
        secondary: "#000000",
        },
    },
    typography: {
        fontFamily: "Arial ",
    },
    });

export default theme;
