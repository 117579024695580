/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
} from '../models/index';

export interface GetFreeTimeCalendarFreeTimeGetRequest {
    startTime?: Date | null;
    endTime?: Date | null;
}

export interface GetIntervalFreeTimesCalendarIntervalFreeTimesGetRequest {
    minutes?: number;
    startTime?: Date | null;
    endTime?: Date | null;
}

/**
 * 
 */
export class CalendarApi extends runtime.BaseAPI {

    /**
     * Authorize
     */
    async authorizeCalendarAuthorizeLocallyGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = await this.configuration.apiKey("x-api-key"); // APIKeyHeader authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api-key"] = await this.configuration.apiKey("api-key"); // APIKeyQuery authentication
        }

        const response = await this.request({
            path: `/calendar/authorize/locally`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Authorize
     */
    async authorizeCalendarAuthorizeLocallyGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.authorizeCalendarAuthorizeLocallyGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Free Time
     */
    async getFreeTimeCalendarFreeTimeGetRaw(requestParameters: GetFreeTimeCalendarFreeTimeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Array<string>>>> {
        const queryParameters: any = {};

        if (requestParameters['startTime'] != null) {
            queryParameters['start_time'] = (requestParameters['startTime'] as any).toISOString();
        }

        if (requestParameters['endTime'] != null) {
            queryParameters['end_time'] = (requestParameters['endTime'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/free_time`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Free Time
     */
    async getFreeTimeCalendarFreeTimeGet(requestParameters: GetFreeTimeCalendarFreeTimeGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Array<string>>> {
        const response = await this.getFreeTimeCalendarFreeTimeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Interval Free Times
     */
    async getIntervalFreeTimesCalendarIntervalFreeTimesGetRaw(requestParameters: GetIntervalFreeTimesCalendarIntervalFreeTimesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Array<string>>>> {
        const queryParameters: any = {};

        if (requestParameters['minutes'] != null) {
            queryParameters['minutes'] = requestParameters['minutes'];
        }

        if (requestParameters['startTime'] != null) {
            queryParameters['start_time'] = (requestParameters['startTime'] as any).toISOString();
        }

        if (requestParameters['endTime'] != null) {
            queryParameters['end_time'] = (requestParameters['endTime'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar/interval_free_times`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get Interval Free Times
     */
    async getIntervalFreeTimesCalendarIntervalFreeTimesGet(requestParameters: GetIntervalFreeTimesCalendarIntervalFreeTimesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Array<string>>> {
        const response = await this.getIntervalFreeTimesCalendarIntervalFreeTimesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
